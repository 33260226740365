import { PortableText } from "@portabletext/react";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import SkeletonBlogV from "../../../components/skeleton/SkeletonBlogV";
import { getDatePost3 } from "../../../helpers/utils";
import { components } from "../../../network/sanity/portableText";
import { fetchBlogData } from "../../../network/services/fetchBlog";
import { RelatedBlog, TagBlog } from "../../../components/inde";
import { urlFor } from "../../../network/sanity/sanityClient";

const BlogDetail = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  // console.log("slug ------------ ", slug);
  // console.log("blog ------------ ", blog?.category);
  useEffect(() => {
    const fetchData = async () => {
      const blog = await fetchBlogData(slug);
      setBlog(blog);
    };
    fetchData();
  }, []);

  return (
    <>
      <Header />
      <Sidebar />
      <Banner title={"News"} backgroundImageUrl={blog?.image} />
      <div className="container-blog-detail">
        <div className="row">
          <div className="col-md-9">
            <div className="container pt-8">
              {!blog && (
                <>
                  {[...Array(2)].map((_, index) => (
                    <SkeletonBlogV key={index} />
                  ))}
                </>
              )}
              {blog && (
                <div className="content-blog">
                  <div className="py-2">
                    <img
                      src={blog?.image}
                      className="w-100 bject-cover img-blog"
                      alt=""
                    />

                    <div className="d-flex gap-2">
                      <NavLink
                        to={`/category-new/${blog?.category?.slug?.en.current}`}
                        className="text-title-category"
                      >
                        {blog?.category?.title}
                      </NavLink>
                      <span className="text-muted">|</span>
                      <span className="text-muted text-date">
                        {blog?.date && getDatePost3(blog?.date)}
                      </span>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center flex-column align-items-center gap-4 pt-4">
                    <span className="text-title-blog">{blog?.title}</span>
                  </div>
                  <div className="py-5 portable-text">
                    <PortableText
                      value={blog?.description}
                      components={components(false)}
                      onMissingComponent={false}
                    />
                  </div>
                  <TagBlog />
                </div>
              )}
            </div>
          </div>
          <div className="col-md-3 container-related-blog">
            <RelatedBlog />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogDetail;
