import { APP_ROLES } from "../network/auth/Auth";
import About from "../screens/frontend/About/About";
import ActiviteMission from "../screens/frontend/Activite-mission/ActiviteMission";
import ActiviteRepresentant from "../screens/frontend/Activite-representant/ActiviteRepresentant";
import Actualite from "../screens/frontend/Actualite/Actualite";
import ActualiteDetails from "../screens/frontend/Actualite/ActualiteDetails";
import AutoriteMaritime from "../screens/frontend/Autorite-maritime/AutoriteMaritime";
import Blog from "../screens/frontend/Blog/Blog";
import Blog2 from "../screens/frontend/Blog/Blog2";
import BlogCategory from "../screens/frontend/Blog/BlogCategory";
import BlogDetail from "../screens/frontend/Blog/BlogDetail";
import BlogDetails2 from "../screens/frontend/Blog/BlogDetails2";
import Contact from "../screens/frontend/Contact/Contact";
import DecouvrirCameroun from "../screens/frontend/Decouvrir-cameroun/DecouvrirCameroun";
import DossiersSpeciaux from "../screens/frontend/Dossiers-speciaux/DossiersSpeciaux";
import Evenement from "../screens/frontend/Evenement/Evenement";
import Home from "../screens/frontend/Home/Home";
import Presentation from "../screens/frontend/Mission-permanente-presentation/Presentation";
import OrganisationAdministrative from "../screens/frontend/Organisation-administrative/OrganisationAdministrative";
import OtherPage from "../screens/frontend/Other-page/OtherPage";
import PaysMembre from "../screens/frontend/Pays-membre/PaysMembre";

import TourismeCameroun from "../screens/frontend/Tourisme-cameroun/TourismeCameroun";
import TraitesConventions from "../screens/frontend/Traite-convention/TraitesConventions";

const routes = [
  // Authentication Routes
  {
    spaceName: "auth",
    // spaceName: "login",
    secure: false,
    routes: [
      {
        component: <Home />,
        path: "/",
      },
      {
        component: <About />,
        path: "/about",
      },
      {
        component: <DecouvrirCameroun />,
        path: "/decouvrir-cameroun",
      },
      {
        component: <TourismeCameroun />,
        path: "/tourisme-cameroun",
      },
      {
        component: <OrganisationAdministrative />,
        path: "/organisation-administrative",
      },

      {
        component: <Presentation />,
        path: "/presentation-mission",
      },

      {
        component: <ActiviteMission />,
        path: "/activite-mission",
      },
      {
        component: <ActiviteRepresentant />,
        path: "/activite-representant",
      },
      {
        component: <Actualite />,
        path: "/actualite",
      },
      {
        component: <ActualiteDetails />,
        path: "/other-details",
      },
      {
        component: <Contact />,
        path: "/contact",
      },
      {
        component: <Evenement />,
        path: "/evenement",
      },
      {
        component: <AutoriteMaritime />,
        path: "/autorite-maritime",
      },
      {
        component: <PaysMembre />,
        path: "/pays-membre",
      },
      {
        component: <DossiersSpeciaux />,
        path: "/dossiers-speciaux",
      },
      {
        component: <TraitesConventions />,
        path: "/traite-convention",
      },
      {
        component: <OtherPage />,
        path: "/other-page/:id",
      },
      {
        component: <Blog />,
        path: "/news",
      },
      {
        component: <Blog2 />,
        path: "/news2",
      },

      {
        component: <BlogCategory />,
        path: "/category-new/:slug",
      },
      {
        component: <BlogDetail />,
        path: "/new/:slugCategory/:slug",
      },
      {
        component: <BlogDetails2 />,
        path: "/news/:slug",
      },
    ],
  },

  // Admin Routes
  {
    spaceName: "rider",
    secure: true,
    role: APP_ROLES.ROLE_PASSAGERS,
    routes: [
      /*  {
        component: <Profile2 />,
        path: "/profile",
      },
     */
    ],
  },
];
export default routes;
