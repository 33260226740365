import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { fetchCategoriesBlogData } from "../../network/services/fetchBlog";
import { SkeletonTitleCategory } from "../inde";

const TagBlog = () => {
  const lang = sessionStorage.getItem("langue");
  const [categories, setCategories] = useState(null);
  // console.log("categories --- ***************************", categories);
  useEffect(() => {
    const fetchData = async () => {
      const categs = await fetchCategoriesBlogData();
      setCategories(categs);
    };

    fetchData();
  }, []);

  return (
    <div className="py-4">
      <div className="d-flex justify-content-start py-4">
        <span className="tag-text-category">
          {lang === "fr"
            ? "Catégories"
            : lang === "en"
              ? "Categories"
              : "Categorías"}
        </span>
      </div>
      {!categories && <SkeletonTitleCategory />}
      {categories && categories?.length > 0 && (
        <div className="category-container">
          <div className="d-flex gap-5 flex-wrap tags-list">
            {categories?.map((item, index) => (
              <NavLink
                to={`/category-new/${item.slug?.en?.current}`}
                key={index}
                className={({ isActive }) =>
                  `tag-button  ${isActive ? "tag-button-active" : ""}`
                }
              >
                {item.title}
              </NavLink>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TagBlog;
