import { PortableText } from "@portabletext/react";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import { useLocation } from 'react-router-dom';
import sanityClient, { urlFor } from "../../../network/sanity/sanityClient";
import { SanityClient } from "@sanity/client";
import Header2 from "../../../components/Header2";
import { getLangue, removeLangue, setLangue } from "../../../network/auth/Auth";
import { getDatePost3 } from "../../../helpers/utils";
import { components } from "../../../network/sanity/portableText";
import { fetchBlogData } from "../../../network/services/fetchBlog";
import { RelatedBlog, TagBlog } from "../../../components/inde";
import RelatedBlog2 from "../../../components/blog/RelatedBlog2";
import CategoryBlock from "../../../components/blog/CategoryBlock";
function BlogDetails2() {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [language, setLanguage] = useState(getLangue());



  const getLanguage = async (data) => {
    console.log("laguage ici", data);
    setLanguage(data);
    const blog = await fetchBlogData(slug, data);
    setBlog(blog);
  };
  // console.log("slug ------------ ", slug);
  // console.log("blog ------------ ", blog?.category);
  useEffect(() => {
    const fetchData = async () => {
      const blog = await fetchBlogData(slug, language);
      setBlog(blog);
    };
    fetchData();
  }, []);


  const receiveDataFromChild = (data) => {
    console.log("data", data);
    //setData(data)
  };


  return (
    <>
      <Header2 sendDataToParent={receiveDataFromChild} sendLanguage={getLanguage} />
      <Sidebar />
      <Banner title={"News"} backgroundImageUrl={blog?.image} />
      {blog && (
        <div class="sidebar-page-container bg-white">
          <div class="auto-container">
            <div class="row clearfix">
              <div class="content-side col-lg-8 col-md-12 col-sm-12">
                <div class="blog-detail">
                  <div class="inner-box">
                    <div class="image">
                      <img src={blog?.image} alt="" />
                    </div>

                    <div class="lower-content">
                      <ul class="post-meta">
                        <li>
                          <span class="icon flaticon-user-2"></span>{language === 'fr' ? "Mission Permanente" : "Permanent Mission"}
                        </li>
                      </ul>

                      <h4>{blog?.title}</h4>

                      <PortableText
                        value={blog?.description}
                        components={components(false)}
                        onMissingComponent={false}
                      />
                    </div>
                  </div>








                </div>
              </div>

              <div class="sidebar-side col-lg-4 col-md-12 col-sm-12">
                <aside class="sidebar sticky-top">

                  <RelatedBlog2 language={language} />
                  <CategoryBlock language={language} />






                </aside>
              </div>
            </div>
          </div>
        </div>)}



      <Footer language={language} />
    </>
  );
}

export default BlogDetails2;
