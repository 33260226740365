import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { filterBlogsBySearch, getDatePost2 } from "../../helpers/utils";
import {
  fetchBlogCategoryData,
  fetchBlogsData,
  fetchCategoriesBlogData
} from "../../network/services/fetchBlog";
import { BlogH1, SkeletonBlogV } from "../inde";
import BlogV from "./BlogV";

const CategoryBlock = ({ language }) => {
  const lang = sessionStorage.getItem("langue");
  const { slugCategory } = useParams();
  const [data, setData] = useState(null);
  const [blogs, setBlogs] = useState([data?.blogs?.slice(0, 2)]);
  const [cats, setCats] = useState();

  // console.log("data ----------------------------- ", data);



  useEffect(() => {
    const fetchData = async () => {
      const categories = await fetchCategoriesBlogData(language)

      setCats(categories)

    };
    fetchData();
  }, [cats]);



  return (
    <>


      <div class="sidebar-widget category-widget">
        <div class="widget-content">
          <div class="sidebar-title-two">
            <h3>{lang === "fr"
              ? "catégories"
              : "categories"}</h3>
          </div>

          <ul class="cat-list">
            {cats &&
              cats?.map((cat, index) =>
                <li class="clearfix">
                  <a >
                    {cat?.title}
                  </a>
                </li>
              )}

          </ul>
        </div>
      </div>
    </>
  );
};

export default CategoryBlock;
