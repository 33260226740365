import React from "react";

import { Link } from "react-router-dom";

function Banner({ title, backgroundImageUrl }) {
  return (
    <>
      <section
        className="page-title"
        style={{ backgroundImage: `url(${backgroundImageUrl})`, backgroundSize: "cover", backgroundPosition: "center" }}
      >
        <div class="auto-container">
          <h1 class=" two-line-text">{title}</h1>

          <ul className="page-breadcrumb">
            <li>
              <a href="/">home</a>
            </li>

            <li style={{ background: "white", padding: "15px" }}>{title}</li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default Banner;
