import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import { fetchActualityHeroSectionData, fetchActualityRequestQuoteSectionData, fetchActualityServiceBlockData } from "../../../network/services/fetchActualityData";
import { fetchPartnersClientsSectionData } from "../../../network/services/fetchHomeData";
import Loading from "../../../components/Loading";
import Header2 from "../../../components/Header2";
import BlogV from "../../../components/blog/BlogV";
import { SkeletonBlogV, TagBlog } from "../../../components/inde";
import { fetchBlogPageData } from "../../../network/services/fetchBlog";
import { getDatePost2 } from "../../../helpers/utils";
import { getLangue } from "../../../network/auth/Auth";

function Blog2() {
  const lang = sessionStorage.getItem("langue");
  const [blogPage, setBlogPage] = useState(null);
  const [blogs, setBlogs] = useState([]);
  // console.log("blogPage ---", blogPage);
  const listRef = useRef(null);
  const n = 4,
    see = 6;
  const [visibleCount, setVisibleCount] = useState(see);
  const [language, setLanguage] = useState(getLangue());
  const getLanguage = async (data) => {
    console.log("laguage ici", data);
    setLanguage(data);
    const blogPage = await fetchBlogPageData(data);
    setBlogPage(blogPage);
  };
  useEffect(() => {
    const fetchData = async () => {
      const blogPage = await fetchBlogPageData(language);
      setBlogPage(blogPage);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (blogPage) {
      setBlogs(blogPage?.blogs);
    }
  }, [blogPage]);

  const showMore = () => {
    setVisibleCount((prevCount) => prevCount + see);
  };

  const showLess = () => {
    setVisibleCount((prevCount) =>
      prevCount - see >= see ? prevCount - see : see
    );
    if (listRef.current) {
      listRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const receiveDataFromChild = (data) => {
    console.log("data", data);
    //setData(data)
  };

  return (
    <>
      {blogPage ? (<>
        <Header2 sendDataToParent={receiveDataFromChild} sendLanguage={getLanguage} />
        <Sidebar />
        <Banner title={"News"} backgroundImageUrl={blogPage?.image} />

        <section className="blog-page-section bg-white" ref={listRef}>
          <div className="auto-container">
            <div className="row clearfix">
              {blogs.length > 0 ? (
                <>
                  {blogs?.slice(0, visibleCount)?.map((data, index) => (
                    <div key={index} className="news-block style-two col-lg-4 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="image">
                          <Link to={`/news/${data?.slug.en.current}`}>
                            <a href={`/news/${data?.slug.en.current}`}>
                              <img src={data?.image} style={{ height: "300px" }} />
                            </a>
                          </Link>
                        </div>
                        <div className="lower-content">
                          <ul className="post-meta">
                            <li>
                              <span class="icon flaticon-user-2"></span>{language === 'fr' ? "Mission Permanente" : "Permanent Mission"}
                            </li>
                          </ul>
                          <h4>
                            <Link to={`/news/${data?.slug.en.current}`}>
                              <a href={`/news/${data?.slug.en.current}`} className="two-line-text"> {data?.title}</a>
                            </Link>
                          </h4>
                          <div className="text two-line-text">
                            {data?.smallDescription}
                          </div>
                          <div className="lower-box">
                            <div className="clearfix">
                              <div className="pull-left">
                                <a href={`/news/${data?.slug.en.current}`} className="read-more">
                                  Read More
                                </a>
                              </div>
                              <div className="pull-right">
                                <a href={`/news/${data?.slug.en.current}`} className="plus-box flaticon-plus-symbol"></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>) : (<div className="h-100 d-flex flex-row justify-content-center pb-5">
                  <span className="no-blog">
                    {lang === "fr"
                      ? "Pas d'actualites pour le moment"
                      : lang === "en"
                        ? "No news for the moment"
                        : "Sin noticias por el momento"}
                  </span>
                </div>)}
            </div>

            {blogs.length > see && (
              <div className="d-flex container-see  justify-content-center">
                {visibleCount < blogs.length && (
                  <button className="see-button" onClick={showMore}>
                    {lang === "fr"
                      ? "Voir plus"
                      : "View more"
                    }
                  </button>
                )}
                {visibleCount > see && (
                  <button className="see-button" onClick={showLess}>
                    {lang === "fr"
                      ? "Voir moins"
                      : "View less"
                    }
                  </button>
                )}
              </div>
            )}
            {/* <ul className="styled-pagination text-center">
                    <li className="prev">
                        <a href="#">
                            <span className="fa fa-angle-left"></span>
                        </a>
                    </li>
                    <li>
                        <a href="#">1</a>
                    </li>
                    <li>
                        <a href="#" className="active">
                            2
                        </a>
                    </li>
                    <li>
                        <a href="#">3</a>
                    </li>
                    <li className="next">
                        <a href="#">
                            <span className="fa fa-angle-right"></span>
                        </a>
                    </li>
                </ul> */}
          </div>
        </section >



        <Footer language={language} />
      </>) : <Loading />}
    </>
  );
}

export default Blog2;
