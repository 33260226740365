import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { fetchHeaderData } from "../network/services/fetchHeaderData";
import { urlFor } from "../network/services/urlImage";
import { fetchSettingsData } from "../network/services/fetchSettingsData";
import sanityClient from "../network/sanity/sanityClient";
import { useTranslation } from "react-i18next";


function Header() {

  const [headerData, setHeaderData] = useState(null);
  const [settings, setSettings] = useState(null);
  const lang = sessionStorage.getItem('langue'); // Récupérer la langue stockée ou utiliser le français par défaut
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {

    sanityClient.fetch(
      `*[_type == "header"][0]{
        logo,
        "descriptionLieu": descriptionLieu.${lang ? lang : 'fr'},
        navigationPrincipale[]{
          nom,
          url
        },
        informationsDeContact[]{
          appeler,
          horairesOuverture,
          email
        }
      }`
    )
      .then((data) => {
        setHeaderData(data);
      })
      .catch(console.error);


    sanityClient.fetch(
      `*[_type == "settings"]{
          logoEntreprise{
            asset->{
              _id,
              url
            }
          },
          iconicWords,
          reseauxSociaux,
          nomEntreprise,
          adresse,
          telephone,
          email
        }[0]`
    )
      .then((data) => {
        console.log("header data", data)
        setSettings(data);
      })
      .catch(console.error);
  }, []);
  const buttonStyles = {
    backgroundColor: isHovered ? '#FFBB1B' : '#1B5E20', // Change these to your colors
    transition: 'background-color 0.3s',
  };

  const changeLanguage = (e) => {
    sessionStorage.setItem('langue', e.target.value)
    window.location.reload();
  }

  if (!headerData) return <div></div>;

  return (
    <>
      <header className="main-header header-style-one">
        <div className="header-top">
          <div className="auto-container">
            <div className="clearfix">
              <div className="top-left clearfix">
                <div className="text">
                  <span className="icon flaticon-chat-bubble"></span> {headerData?.descriptionLieu}
                </div>
              </div>

              <div className="top-right pull-right">
                <ul className="nav-list">
                  <li>
                    <select className="language-selector" value={lang} onChange={(e) => changeLanguage(e)}>
                      <option value="en">English</option>
                      <option value="fr">Français</option>
                      <option value="es">Español</option>
                    </select>
                  </li>
                </ul>

                <ul className="social-nav">
                  <li>
                    <a href="#">
                      <span className="fa fa-dribbble"></span>
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <span className="fa fa-google-plus"></span>
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <span className="fa fa-twitter"></span>
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <span className="fa fa-facebook-f"></span>
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <span className="fa fa-pinterest-p"></span>
                    </a>
                  </li>


                </ul>
              </div>

            </div>
          </div>
        </div>

        <div className="header-upper">
          <div className="auto-container">
            <div className="clearfix">
              <div className="pull-left logo-box">
                <div className="logo">
                  <a href="/">
                    {headerData?.logo ? <img src={urlFor(headerData.logo).url()} alt="" title="" /> : ""}
                  </a>
                </div>
              </div>

              <div className="pull-right upper-right clearfix">
                <div className="upper-column info-box">
                  <div className="icon-box">
                    <span className="fa fa-phone"></span>
                  </div>

                  <ul>
                    <li>
                      <strong>
                        {lang === 'fr'
                          ? 'Appelez-nous à tout moment'
                          : lang === 'en'
                            ? 'Call Us Anytime'
                            : 'Llámenos en cualquier momento'}
                      </strong>
                    </li>

                    {headerData?.informationsDeContact.map((info, index) => (
                      <li key={index}>
                        {info.appeler}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="upper-column info-box">
                  <div className="icon-box">
                    <span className="fa fa-clock-o"></span>
                  </div>

                  <ul>
                    <li>
                      <strong>
                        {lang === 'fr'
                          ? 'Heures d\'ouverture'
                          : lang === 'en'
                            ? 'Opening Time'
                            : 'Horario de apertura'}
                      </strong>
                    </li>

                    {headerData?.informationsDeContact && headerData?.informationsDeContact?.map((info, index) => (
                      <li key={index}>
                        {info.horairesOuverture}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="upper-column info-box">
                  <div className="icon-box">
                    <span className="fa fa-envelope-o"></span>
                  </div>

                  <ul>
                    <li>
                      <strong>
                        {lang === 'fr'
                          ? 'Envoyez-nous un e-mail'
                          : lang === 'en'
                            ? 'Email Us Now'
                            : 'Envíenos un correo electrónico'}
                      </strong>
                    </li>

                    {headerData?.informationsDeContact && headerData?.informationsDeContact?.map((info, index) => (
                      <li key={index}>
                        {info.email}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-lower">
          <div className="auto-container">
            <div className="nav-outer clearfix">
              <div className="mobile-nav-toggler">
                {/* <span className="icon flaticon-menu-2"></span> */}
              </div>

              <nav className="main-menu show navbar-expand-md">
                <div className="navbar-header">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="icon-bar"></span>

                    <span className="icon-bar"></span>

                    <span className="icon-bar"></span>
                  </button>
                </div>

                <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                  <ul className="navigation clearfix">
                    <li>
                      <Link to="/">
                        {lang === 'fr' ? 'Accueil' : lang === 'en' ? 'Home' : 'Inicio'}
                      </Link>
                    </li>
                    <li className="dropdown position-static">
                      <a href="#">
                        {lang === 'fr' ? 'Mission permanente' : lang === 'en' ? 'Permanent Mission' : 'Misión permanente'}
                      </a>
                      <ul>
                        <li>
                          <Link to="/presentation-mission">
                            {lang === 'fr' ? 'Présentation' : lang === 'en' ? 'Presentation' : 'Presentación'}
                          </Link>
                        </li>
                        <li>
                          <Link to="/activite-mission">
                            {lang === 'fr' ? 'Activités de la Mission' : lang === 'en' ? 'Mission Activities' : 'Actividades de la Misión'}
                          </Link>
                        </li>
                        <li>
                          <Link to="/activite-representant">
                            {lang === 'fr' ? 'Activités du Représentant' : lang === 'en' ? 'Representative Activities' : 'Actividades del Representante'}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown position-static">
                      <a href="#">
                        {lang === 'fr' ? 'Cameroun' : lang === 'en' ? 'Cameroon' : 'Camerún'}
                      </a>
                      <ul>
                        <li>
                          <Link to="/decouvrir-cameroun">
                            {lang === 'fr' ? 'Découvrir le Cameroun' : lang === 'en' ? 'Discover Cameroon' : 'Descubrir Camerún'}
                          </Link>
                        </li>
                        <li>
                          <Link to="/tourisme-cameroun">
                            {lang === 'fr' ? 'Tourisme au Cameroun' : lang === 'en' ? 'Tourism in Cameroon' : 'Turismo en Camerún'}
                          </Link>
                        </li>
                        <li>
                          <Link to="/organisation-administrative">
                            {lang === 'fr' ? 'Organisation Administrative' : lang === 'en' ? 'Administrative Organization' : 'Organización Administrativa'}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown position-static">
                      <a href="#">
                        {lang === 'fr' ? 'Nations Unies' : lang === 'en' ? 'United Nations' : 'Naciones Unidas'}
                      </a>
                      <ul>
                        <li>
                          <a href="/pays-membre">
                            {lang === 'fr' ? 'Pays Membres' : lang === 'en' ? 'Member States' : 'Estados Miembros'}
                          </a>
                        </li>
                        <li>
                          <a href="/dossiers-speciaux">
                            {lang === 'fr' ? 'Dossiers spéciaux' : lang === 'en' ? 'Special Dossiers' : 'Expedientes especiales'}
                          </a>
                        </li>
                        <li>
                          <a href="/traite-convention">
                            {lang === 'fr' ? 'Traités et Conventions' : lang === 'en' ? 'Treaties and Conventions' : 'Tratados y Convenciones'}
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/autorite-maritime">
                        {lang === 'fr' ? 'Autorité maritime' : lang === 'en' ? 'Maritime Authority' : 'Autoridad marítima'}
                      </Link>
                    </li>
                    <li>
                      <Link to="/actualite">
                        {lang === 'fr' ? 'Actualités' : lang === 'en' ? 'News' : 'Noticias'}
                      </Link>
                    </li>
                    <li>
                      <Link to="/evenement">
                        {lang === 'fr' ? 'Événements' : lang === 'en' ? 'Events' : 'Eventos'}
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>

              <div className="options-box clearfix">
                <div className="search-box-outer">
                  <div className="search-box-btn">
                    <span className="fa fa-search"></span>
                  </div>
                </div>

                <div className="button-box">
                  <Link to="/contact" className="theme-btn btn-style-one"
                    style={buttonStyles}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <span className="txt">
                      Contact <i className="fa fa-phone"></i>
                    </span>
                  </Link>
                </div>

                {/* <div className="nav-btn navSidebar-button">
                  <span className="icon flaticon-menu"></span>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-menu">
          <div className="menu-backdrop"></div>

          <div className="close-btn">
            <span className="icon flaticon-multiply"></span>
          </div>

          <nav className="menu-box">
            <div className="nav-logo">
              <a href="index-2.html">
                <img src="assets/images/logo.png" alt="" title="" />
              </a>
            </div>

            <div className="menu-outer"></div>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;
