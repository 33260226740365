import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import sanityClient from "../../../network/sanity/sanityClient";
import { urlFor } from "../../../network/services/urlImage";
import { getLangue } from "../../../network/auth/Auth";
import Header2 from "../../../components/Header2";
import Footer from "../../../components/Footer";

function Home() {
  const [heroSection, setHeroSection] = useState([]);
  const [introData, setIntroData] = useState(null);
  const [cardsData, setCardsData] = useState([]);
  const [discoverData, setDiscoverData] = useState(null);
  const [teamData, setTeamData] = useState(null);
  const [logo, setLogo] = useState(null);
  const [lowerText, setLowerText] = useState(null);
  const [clientLogos, setClientLogos] = useState([]);
  const [textSlider, setTextSlider] = useState("");
  const [language, setLanguage] = useState(getLangue());
  const [leaderTitle, setLeaderTitle] = useState()
  const [leaderDescription, setLeaderDescription] = useState()

  const options = {
    items: 3,
    margin: 30,
    loop: true,
    nav: true,
    dots: true,
  };

  const fetchData = async () => {
    const currentLanguage = getLangue();
    setLanguage(currentLanguage);

    try {
      const heroData = await sanityClient.fetch(
        `*[_type == "home"]{
          heroSection[] {
            "textePrincipal": textePrincipal,
            "texteSecondaire": texteSecondaire,
            "description": description,
            "boutonsAppelAction": boutonsAppelAction[] {
              "texte": texte,
              "url": url
            },
            "imageFond": imageFond.asset->{
              _id,
              url
            }
          }
        }[0]`
      );

      // Vérifiez que chaque propriété existe avant de l'utiliser
      const translatedHeroData = heroData.heroSection.map(slide => ({
        ...slide,
        textePrincipal: slide.textePrincipal?.[currentLanguage] ?? '',
        texteSecondaire: slide.texteSecondaire?.[currentLanguage] ?? '',
        description: slide.description?.[currentLanguage] ?? '',
        boutonsAppelAction: slide.boutonsAppelAction?.map(btn => ({
          ...btn,
          texte: btn.texte?.[currentLanguage] ?? ''
        })) ?? []
      }));

      setHeroSection(translatedHeroData);
      setTextSlider(translatedHeroData[0]?.textePrincipal || "");

      const introData = await sanityClient.fetch(
        `*[_type == "home"]{
          introductionSection {
            "titre": titre,
            "sousTitre": sousTitre
          }
        }[0].introductionSection`
      );

      // Vérification des données de l'introduction
      setIntroData({
        titre: introData?.titre?.[currentLanguage] ?? '',
        sousTitre: introData?.sousTitre?.[currentLanguage] ?? ''
      });

      const cardsData = await sanityClient.fetch(
        `*[_type == "home"]{
          informationCardsSection {
            cartesInformatives[] {
              "nom": nom,
              "description": description,
              "imageUrl": image.asset->url
            }
          }
        }[0].informationCardsSection.cartesInformatives`
      );

      // Vérification des données des cartes
      setCardsData(cardsData.map(card => ({
        ...card,
        nom: card.nom?.[currentLanguage] ?? '',
        description: card.description?.[currentLanguage] ?? ''
      })));

      const discoverData = await sanityClient.fetch(
        `*[_type == "home"]{
          discoverSection {
            "titre": titre,
            "description": description,
            "backgroundUrl": background.asset->url,
            "media": media[] {
              "mediaUrl": asset->url
            },
            "linkVideo": linkVideo
          }
        }[0].discoverSection`
      );

      // Vérification des données de la section Découverte
      setDiscoverData({
        ...discoverData,
        titre: discoverData?.titre?.[currentLanguage] ?? '',
        description: discoverData?.description?.[currentLanguage] ?? ''
      });

      const leadershipData = await sanityClient.fetch(
        `*[_type == "home"]{
          leadershipSection {
            "titre": titre,
            cartesLeaders[] {
              "nom": nom,
              "titre": titre,
              "imageUrl": image.asset->url,
              twitter,
              facebook,
              LinkedIn,
              google
            }
          }
        }[0].leadershipSection`
      );

      // Vérification des données de l'équipe
      setTeamData({
        ...leadershipData,
        titre: leadershipData?.titre?.[currentLanguage] ?? '',
        cartesLeaders: leadershipData.cartesLeaders?.map(leader => ({
          ...leader,
          nom: leader.nom?.[currentLanguage] ?? '',
          titre: leader.titre?.[currentLanguage] ?? ''
        })) ?? []
      });

      const clientLogosData = await sanityClient.fetch(
        `*[_type == "home"]{
          partnersClientsSection {
            logos[] {
              "imageUrl": asset->url
            }
          }
        }[0].partnersClientsSection.logos`
      );
      setClientLogos(clientLogosData ?? []);

      const lowerTextData = await sanityClient.fetch(
        `*[_type == "home"]{
          "lowerText": {
            "text": lowerText.text,
            "linkText": lowerText.linkText,
            "linkUrl": lowerText.linkUrl
          }
        }[0].lowerText`
      );

      // Vérification du texte inférieur
      setLowerText({
        text: lowerTextData?.text?.[currentLanguage] ?? '',
        linkText: lowerTextData?.linkText?.[currentLanguage] ?? '',
        linkUrl: lowerTextData?.linkUrl ?? ''
      });

      const logoData = await sanityClient.fetch(
        `*[_type == "settings"]{
          logoEntreprise {
            asset-> {
              _id,
              url
            }
          }
        }[0]`
      );
      setLogo(logoData.logoEntreprise?.asset?.url || "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { backgroundUrl, media, linkVideo, titre, description } = discoverData || {};
  const mediaUrl = media && media?.length > 0 ? media[0].mediaUrl : '';

  const receiveDataFromChild = (data) => {
    console.log("Received data from child:", data);
  };

  return (
    <>
      {heroSection.length > 0 && (
        <>
          <Header2 sendDataToParent={receiveDataFromChild} sendLanguage={fetchData} />
          <Sidebar />

          <section className="main-slider slider-home">
            <OwlCarousel className="main-slider-carousel owl-carousel owl-theme" loop items={1} margin={10} nav autoplay>
              {heroSection.map((slide, index) => (
                <div
                  key={index}
                  className="slide item"
                  style={{
                    backgroundImage: `url(${slide.imageFond ? urlFor(slide.imageFond).url() : "default-image-url"})`,
                  }}
                >
                  <div className="color-layer"></div>
                  <div className="auto-container">
                    <div className="content-boxed">
                      <div className="title">{slide.texteSecondaire}</div>
                      <h1>{textSlider}</h1>
                      <div className="text">{slide.description}</div>
                      <div className="link-box clearfix">
                        {slide.boutonsAppelAction && slide.boutonsAppelAction.length > 0 && (
                          <>
                            <a
                              href={slide.boutonsAppelAction[0]?.url || '#'}
                              className="theme-btn btn-style-three"
                            >
                              <span className="txt">{slide.boutonsAppelAction[0]?.texte}</span>
                            </a>
                            <a
                              href={slide.boutonsAppelAction[1]?.url || '#'}
                              className="theme-btn btn-style-four"
                            >
                              <span className="txt">{slide.boutonsAppelAction[1]?.texte}</span>
                            </a>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </section>

          <section className="service-section">
            <div className="auto-container">
              <div className="row clearfix">
                {cardsData.map((card, index) => (
                  <div key={index} className="service-block col-lg-3 col-md-6 col-sm-12">
                    <div className="inner-box" style={{ height: "320px" }}>
                      <img src={card.imageUrl} alt={card.nom} style={{ height: "111px" }} />
                      <h4>{card.nom}</h4>
                      <div className="text">{card.description}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>


          <section className="team-section pb-0">
            <div className="color-layer"></div>

            <div className="auto-container">
              <div className="sec-title centered">
                <div className="separater"></div>

                <div className="title">{leaderDescription}</div>

                <h2>{leaderTitle}</h2>
              </div>

              <div
                className="four-item-carousel  owl-theme row"
                options={options}
              >
                {teamData?.cartesLeaders && teamData?.cartesLeaders?.map((member, index) => (
                  <div className="team-block design-perso-block item col-sm-4" key={index}>
                    <div className="inner-box">
                      <div
                        className="image"
                        style={{
                          height: '400px',
                          backgroundImage: `url(${member?.imageUrl})`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                        }}
                      >
                        <div className="circular-menu">
                          <div className="items-wrapper">
                            {member?.facebook && (
                              <a href={member.facebook} className="menu-item">
                                <span className="fa fa-facebook"></span>
                              </a>
                            )}
                            {member?.twitter && (
                              <a href={member.twitter} className="menu-item">
                                <span className="fa fa-twitter"></span>
                              </a>
                            )}
                            {member?.google && (
                              <a href={member.google} className="menu-item">
                                <span className="fa fa-google"></span>
                              </a>
                            )}
                            {member?.LinkedIn && (
                              <a href={member.LinkedIn} className="menu-item">
                                <span className="fa fa-linkedin"></span>
                              </a>
                            )}
                          </div>
                          <button className="floating-btn">
                            <i className="flaticon-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div className="lower-box">
                        <h3>
                          <a href="#">{member?.nom}</a>
                        </h3>
                        <div className="designation">{member?.titre}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <section
            className="fluid-section-one"
            style={{
              backgroundImage: `url(${backgroundUrl})`,
            }}
          >
            <div className="outer-container clearfix">
              <div className="content-column">
                <div className="inner-column">
                  <div className="sec-title light">
                    <div className="separater"></div>
                    <h2>{titre}</h2>
                  </div>
                  <div className="text">
                    {description}
                  </div>
                </div>
              </div>

              <div className="image-column">
                <figure className="image-box">
                  {mediaUrl ? (
                    <img src={mediaUrl} alt="Discover Cameroon" />
                  ) : (
                    ""
                  )}
                </figure>

                <a
                  href={linkVideo}
                  className="lightbox-image video-box"
                >
                  <span className="fa fa-play">
                    <i className="ripple"></i>
                  </span>
                </a>

                <div className="color-layer"></div>
                <div className="color-layer-two"></div>
              </div>
            </div>
          </section>
          <section className="clients-section bg-white">
            <div className="auto-container">
              <div className="inner-container">
                <div className="clearfix">
                  {clientLogos && clientLogos?.map((logo, index) => (
                    <div className="column col-lg-3 col-md-6 col-12" key={index}>
                      <div className="image text-center">
                        <a href="#">
                          <img src={logo.imageUrl} alt={`Client ${index + 1}`} style={{
                            height: "200px",
                            width: "200px"
                          }} />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>

          <Footer language={language} />
        </>
      )}
    </>
  );
}

export default Home;
