import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import { fetchDossiersSpeciauxBannerSectionData, fetchDossiersSpeciauxBlogSectionData, fetchDossiersSpeciauxClientsSectionData, fetchDossiersSpeciauxSidebarSectionData } from "../../../network/services/fetchDossierSpeciaux";
import Loading from "../../../components/Loading";

function DossiersSpeciaux() {
  const [bannerSectionData, setBannerSectionData] = useState();
  const [blogSectionData, setBlogSectionData] = useState();
  const [sidebarSectionData, setSidebarSectionData] = useState();
  const [clientsSectionData, setClientsSectionData] = useState();
  const lang = sessionStorage.getItem('langue') || 'fr'; // Récupère la langue stockée ou utilise le français par défaut

  useEffect(() => {
    const fetchData = async () => {
      const bannerData = await fetchDossiersSpeciauxBannerSectionData();
      console.log("Banner Data : ", bannerData);
      setBannerSectionData(bannerData);

      const blogData = await fetchDossiersSpeciauxBlogSectionData();
      console.log("Blog Data : ", blogData);
      setBlogSectionData(blogData);

      const sidebarData = await fetchDossiersSpeciauxSidebarSectionData();
      console.log("Sidebar Data : ", sidebarData);
      setSidebarSectionData(sidebarData);

      const clientsData = await fetchDossiersSpeciauxClientsSectionData();
      console.log("Clients Data : ", clientsData);
      setClientsSectionData(clientsData);
    };

    fetchData();
  }, []);

  return (
    <>

      <Header />
      <Sidebar />
      {bannerSectionData && (
        <Banner title={bannerSectionData[0].textePrincipal} backgroundImageUrl={bannerSectionData[0].imageFond.url} />
      )}

      <div class="sidebar-page-container bg-white">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="content-side col-lg-8 col-md-12 col-sm-12">
              <div class="blog-detail">
                <div class="inner-box">
                  {
                    blogSectionData ? (
                      <div class="lower-content pt-5">

                        <ul class="cat-list">
                          {/* Affichage des éléments de la section blog */}
                          {blogSectionData?.map((item, index) => (
                            <li class="clearfix" key={index}>
                              <a href="#">{item?.title}</a>
                            </li>
                          ))}
                        </ul>



                      </div>) : ""
                  }

                </div>
              </div>
            </div>

            {/* Affichage de la barre latérale avec les éléments de la section "Quick links" */}
            <div class="sidebar-side col-lg-4 col-md-12 col-sm-12">
              <aside class="sidebar sticky-top">
                <div class="sidebar-widget category-widget">
                  <div class="widget-content">
                    <div class="sidebar-title-two">
                      <h3>Quick links</h3>
                    </div>

                    <ul class="cat-list">
                      {/* Affichage des éléments "Quick links" */}
                      {sidebarSectionData && sidebarSectionData?.map((item, index) => (
                        <li class="clearfix" key={index}>
                          <a href="#">{item?.title}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* Affichage de la galerie */}
                <div class="sidebar-widget gallery-widget">
                  <div class="sidebar-title-two">
                    <h3>gallery</h3>
                  </div>

                  <div class="gallery-outer clearfix">
                    {/* Affichage des images de la galerie */}
                    {sidebarSectionData && sidebarSectionData?.map((item, index) => (
                      <figure class="image" key={index}>
                        <a href={item?.image?.url} class="lightbox-image" title="Image Title Here">
                          <img src={item?.image?.url} alt="" />
                        </a>
                      </figure>
                    ))}
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div >


      <section
        class="clients-section-three style-two"
        style={{
          backgroundImage: "url(assets/images/background/pattern-21.png)",
        }}
      >
        <div class="auto-container">
          <div class="row clearfix">


            <div class="clients-column col-lg-7 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="row clearfix">
                  {/* Affichage des clients */}
                  {clientsSectionData?.clients?.map((client, index) => (
                    <div class="client-box col-lg-4 col-md-4 col-sm-12" key={index}>
                      <div class="image">
                        {/* Utilisation de l'opérateur de navigation optionnelle pour éviter les erreurs si client.image est null */}
                        <a href="#">
                          <img src={client?.image?.url} alt="" />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <Footer />

    </>
  );
}

export default DossiersSpeciaux;
