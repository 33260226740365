import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { filterBlogsBySearch } from "../../helpers/utils";
import {
  fetchBlogCategoryData,
  fetchBlogsData
} from "../../network/services/fetchBlog";
import { BlogH1, SkeletonBlogV } from "../inde";
import BlogV from "./BlogV";

const RelatedBlog = () => {
  const lang = sessionStorage.getItem("langue");
  const { slugCategory } = useParams();
  const [data, setData] = useState(null);
  const [blogs, setBlogs] = useState([data?.blogs?.slice(0, 2)]);

  // console.log("data ----------------------------- ", data);

  useEffect(() => {
    const fetchData = async () => {
      const blog = slugCategory
        ? await fetchBlogCategoryData(slugCategory)
        : await fetchBlogsData();
      setData(blog);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      if (data?.blogs) setBlogs(data?.blogs?.slice(0, 2));
      else setBlogs(data?.slice(0, 2));
    }
  }, [data]);

  const onSearch = (text) => {
    if (text) {
      setBlogs(filterBlogsBySearch(data?.blogs, text));
    } else {
      setBlogs(data?.blogs?.slice(0, 2));
    }
  };
  return (
    <div className="container">
      <input
        type="search"
        onChange={(e) => onSearch(e.target.value)}
        className="form-control w-100 rounded-full custom-input-blog font-italic"
        style={{ border: "1px solid rgba(220,220,220)" }}
        placeholder={`${lang === "fr" ? "Recherche" : lang === "en" ? "Search" : "Search"
          }`}
      />
      <div className="text-related-posts">
        {lang === "fr"
          ? "Postes associés"
          : lang === "en"
            ? "Related Posts"
            : "Related Posts"}
      </div>
      {!data && <SkeletonBlogV />}
      {data && (
        <div className="grid-related">
          {blogs &&
            blogs?.map((blog, index) => <BlogH1 data={blog} key={index} />)}
        </div>
      )}
    </div>
  );
};

export default RelatedBlog;
